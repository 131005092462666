<template>
  <header>
    <div class="head">
      <a href="/">
        <img src="@/assets/img/LOGO@2x.png" class="logo" alt="" />
      </a>
      <div class="haed-nav">
        <router-link to="/" class="nav">
          <span>首页推荐</span>
        </router-link>
        <router-link to="/myCourse" class="nav">
          <span>我的课程</span>
        </router-link>
        <router-link to="/liveCouse" class="nav">
          <span>解决方案</span>
        </router-link>
        <router-link to="/examination/center" class="nav">
          <span>考试中心</span>
        </router-link>
        <router-link to="/appraisalCenter" class="nav">
          <span>测评中心</span>
        </router-link>
      </div>
      <div class="search" to="/search">
        <!-- <el-select v-model="value" placeholder="课程">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <!-- <div class="cut">
          <span>课程</span>
          <img class="cut-icon" src="../assets/img/ic-cut@2x.png" alt="" />
        </div> -->
        <input type="text" v-model.trim="keyword" @keydown="handleKeyCode($event)" placeholder="搜索" />
        <img @click="search" src="../assets/img/search@2x.png" class="search-btn" alt="" />
        <!-- <a href="javascript:;" class="search-btn" @click="search">搜索</a> -->
      </div>
      <div class="user-right">
        <!-- <el-popover placement="bottom-end" trigger="hover">
          <img src="@/assets/image/code.png" class="download-image" alt="" />
          <a href="javascript:;" slot="reference" class="download">下载APP</a>
        </el-popover>
        <a href="javascript:;" @click="myCourse" class="my-course">我的课程</a> -->

        <el-popover placement="bottom-end" v-if="token" trigger="hover">
          <div class="user-pop">
            <div class="user-info">
              <img :src="userInfo.avatar_url || require('@/assets/img/avatar@2x.jpg')
                " class="avatar-image" alt="" />
              <div class="user">
                <p class="name line-clamp-1">
                  {{ userInfo.real_name || userInfo.nick_name || "学员" }}
                </p>
                <p class="mobile">{{ userInfo.new_mobile }}</p>
              </div>
            </div>
            <div class="ul">
              <router-link to="/my/information" class="li">
                <span class="label">个人中心</span>
              </router-link>
              <!-- <router-link to="/my/authentication" class="li">
                <span class="label">实名认证</span>
                <span class="value">
                  {{ userInfo.is_license ? "已实名" : "去实名" }}
                  <i class="el-icon-arrow-right"></i>
                </span>
              </router-link>
              <router-link to="/my/wrongQuestion" class="li">
                <span class="label">错题本</span>
              </router-link>
              <router-link to="/my/collectQuestions" class="li">
                <span class="label">我的收藏</span>
              </router-link> -->
            </div>
            <div class="ul" v-if="userInfo.reg_code">
              <router-link to="/my/apply" class="li">我的邀请码</router-link>
            </div>
            <div class="ul">
              <router-link to="/my/activation" class="li">激活课程</router-link>
            </div>
            <!-- <div class="ul">
              <router-link to="" class="li">
                <span class="label">我的学分</span>
              </router-link>
              <router-link to="" class="li">
                <span class="label">报考</span>
                <span class="value">
                  学分达标可报考
                  <i class="el-icon-arrow-right"></i>
                </span>
              </router-link>
              <a href="javascript:;" @click="connectShow = true" class="li">
                <span class="label">联系客服</span>
              </a>
            </div> -->
            <div class="ul">
              <router-link to="/my/wrongQuestion" class="li">
                <span class="label">错题本</span>
              </router-link>
              <router-link to="/my/collectJobs" class="li">
                <span class="label">我收藏的课程</span>
              </router-link>
              <!-- <router-link to="/my/article?id=10003" class="li">
                <span class="label">关于</span>
              </router-link> -->
              <a href="javascript:;" @click="logOut" class="li">
                <span class="label">退出登录</span>
              </a>
            </div>
          </div>

          <a href="javascript:;" slot="reference" class="login">
            <span class="line-clamp-1">{{
              userInfo.real_name || userInfo.nick_name || "学员"
            }}</span>
            <div class="avatar">
              <img :src="getAvatar" alt="" />
            </div>
          </a>
        </el-popover>

        <router-link to="/login" class="login" v-if="!token">
          <span>登录/注册</span>
          <div class="avatar"></div>
        </router-link>
      </div>

      <el-dialog width="340px" title="请先绑定手机号码" :append-to-body="true" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" :visible.sync="dialogFormVisible">
        <el-form :model="form" ref="bindForm" :rules="rules">
          <el-form-item label="手机号码" prop="mobile">
            <el-input v-model="form.mobile" maxlength="11" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsCode">
            <div class="code-wrap">
              <el-input v-model="form.smsCode" autocomplete="off"></el-input>
              <a href="javascript:;" :class="{ disable: number > 0 }" @click="getCode" class="code-btn">{{ getCodeText
              }}</a>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="logOut">退出登录</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog width="480px" :append-to-body="true" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" :visible.sync="has_merge">
        <div class="merge-title" slot="title">
          <i class="el-icon-warning"></i>
          <span>异常提示</span>
        </div>
        <div class="merge-content">
          <p class="text">为了给您提供更优质、一致的服务体验，我们检测到您的手机号：{{ form.mobile }} 存在多个数据记录。</p>
          <p class="mesg">为确保账号信息的完整性和安全性，中智大学堂建议您进行账号数据合并。  </p>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="has_merge = false">暂不合并</el-button>
          <el-button type="primary" @click="mergeUser" :loading="loading">{{loading ? "合并中" : "确定合并"}}</el-button>
        </div>
      </el-dialog>

      <el-dialog title="联系客服" width="350px" :modal-append-to-body="false" :visible.sync="connectShow">
        <div class="connect-pop">
          <p class="name">华数云AI</p>
          <img :src="connectUrl" alt="" />
          <p class="tips">微信扫描二维码，添加客服！</p>
        </div>
      </el-dialog>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { userInfo, bindMobile, mergeUser } from '@/api/user'
import { shareConfig } from '@/api/job'
import { sendSmsCaptcha } from '@/api/login'

export default {
  name: 'pageHeader',
  computed: {
    ...mapState({
      token: (state) => state.user.token
    }),

    getCodeText () {
      const { number } = this
      if (number > 0) return `重新获取（${number}）`
      return '获取验证码'
    },

    getAvatar () {
      const { avatar_url } = this.userInfo
      if (avatar_url) {
        return avatar_url
      }
      return require('@/assets/img/avatar@2x.jpg')
    }
  },

  data () {
    return {
      has_merge: false,
      loading: false,
      options: [
        {
          value: '课程',
          label: '课程'
        },
        {
          value: '机构',
          label: '机构'
        }
      ],
      value: '课程',
      keyword: '',
      userInfo: {
        avatar_url: ''
      },
      form: {
        mobile: '',
        smsCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      connectUrl: '',
      connectShow: false,
      dialogFormVisible: false,
      number: 0
    }
  },

  props: {},

  mounted () {
    setTimeout(() => {
      this.keyword = this.$route.query.keyword
    }, 500)
    if (this.token) {
      this.getUser()
    }
    this.getShareConfig()
  },

  watch: {
    token (value) {
      if (value) {
        this.getUser()
      }
    }
  },

  methods: {
    handleKeyCode (event) {
      if (event.keyCode === 13) {
        this.search()
      }
    },

    mergeUser () {
      this.loading = true
      mergeUser({ mobile: this.form.mobile }).then(result => {
        if (result.data.data) {
          this.$store.dispatch('switchUser', result.data.data.token)
          this.has_merge = false
          this.$message.success('合并成功')
          setTimeout(() => {
            location.reload()
          }, 1500)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    getUser () {
      userInfo().then((result) => {
        this.userInfo = result.data.userInfo
        const { mobile } = this.userInfo
        if (!mobile) { // 未绑定手机号
          this.dialogFormVisible = true
        }
      })
    },

    submitForm () {
      this.$refs.bindForm.validate((valid) => {
        if (valid) {
          bindMobile({ form: this.form }).then(result => {
            this.dialogFormVisible = false
            if (result.data.has_merge) {
              this.has_merge = true
            } else {
              this.$message.success('绑定成功')
            }
          })
        }
      })
    },

    getCode () {
      const {
        number,
        form: { mobile }
      } = this
      if (!mobile) return this.$message.error('请输入手机号码')
      if (number > 0) return
      this.number = 60
      sendSmsCaptcha({ form: { sense: 1, mobile } })
        .then((result) => {
          const time = setInterval(() => {
            this.number--
            if (this.number <= 0) {
              this.number = 0
              clearInterval(time)
            }
          }, 1000)
          this.$message.success(result.message)
        })
        .catch(() => {
          this.number = 0
        })
    },

    getShareConfig () {
      shareConfig().then((result) => {
        this.connectUrl = result.data.share_agent_url
      })
    },

    search () {
      const { keyword } = this
      if (keyword) {
        if (this.$route.path === '/jobInfo') {
          this.$router.replace(
            { path: '/jobInfo', query: { keyword, type: this.value, cId: '' } },
            () => { }
          )
        } else {
          this.$router.push({
            path: '/jobInfo',
            query: { keyword, type: this.value, cId: '' }
          })
        }
      } else {
        this.$message.error('请输入搜索内容')
      }
    },

    myCourse () {
      const { token } = this
      if (this.$route.path === '/login') return
      if (token) {
        // const routeUrl = this.$router.resolve({ path: '/myCourse' })
        // window.open(routeUrl.href, '_blank')
        this.$router.push({ path: '/myCourse' })
      } else {
        // 未登录
        this.$router.push({ path: '/login' })
      }
    },

    logOut () {
      this.$confirm('是否确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'exitConfirm'
      })
        .then(() => {
          this.$store.dispatch('LogOut', {}).then(() => {
            this.$message.success('退出成功')
            setTimeout(() => {
              WeixinJSBridge.call('closeWindow')
            }, 1000)
          })
        })
        .catch(() => { })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.download-image {
  width: 150px;
}

.merge-title {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0,0,0,0.9);
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 20px0 10px;
  i {
    color: #FF5D09;
    font-size: 22px;
    margin-right: 8px;
  }
}

.merge-content {
  font-size: 14px;
  color: #030303;
  line-height: 22px;
  padding: 0 20px 0 32px;
  .mesg {
    font-weight: 600;
    margin-top: 10px;
  }
}

.code-wrap {
  display: flex;
  width: 100%;

  .code-btn {
    flex-shrink: 0;
    width: 120px;
    border: 1px solid #0675f8;
    color: #0675f8;
    border-radius: 6px;
    margin-left: 10px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;

    &.disable {
      border: 1px solid #d6d6d6;
      color: #d6d6d6;
    }
  }
}

.search /deep/ .el-input__inner {
  border: none;
  background: none;
  color: #333;
}

/deep/ .el-select {
  width: 80px;
}

.el-input__inner::-webkit-input-placeholder {
  color: red;
}

.user-pop {
  width: 150px;

  .user-info {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    .avatar-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      background-image: url("@/assets/img/avatar@2x.jpg");
      background-size: 100%;
      flex-shrink: 0;
    }

    .user {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;

      .name {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #4a4a4a;
      }

      .mobile {
        color: #a6a5b3;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .ul {
    padding: 10px 0;
    border-top: 1px solid #f1f1f1;

    .li {
      display: flex;
      align-items: center;
      color: #4a4a4a;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      margin-top: 16px;

      &:first-child {
        margin: 0;
      }

      .value {
        margin-left: auto;
        color: #a6a5b3;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}

header {
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  font-size: 13px;

  .head {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    height: 52px;

    .logo {
      width: 175px;
      height: 34px;
    }
  }

  .haed-nav {
    display: flex;
    margin-left: 44px;
    box-sizing: border-box;

    .nav {
      position: relative;
      flex: 1;
      // height: 54px;
      // line-height: 54px;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
      color: #333;
      font-size: 15px;
      font-weight: 500;
      color: #030303;
      margin-right: 44px;
      box-sizing: border-box;
    }

    .nav:hover :after {
      position: absolute;
      left: 25px;
      bottom: -6px;
      content: "";
      width: 10px;
      height: 2px;
      background: #0675f8;
      border-radius: 1px;
    }
  }

  .router-link-exact-active:after {
    position: absolute;
    left: 25px;
    bottom: -6px;
    content: "";
    width: 10px;
    height: 2px;
    background: #0675f8;
    border-radius: 1px;
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 12px 0 16px;
    padding-right: 10px;
    margin-left: 24px;
    height: 36px;
    background: #f8f9fa;
    border-radius: 5px;
    box-sizing: border-box;

    .cut {
      display: flex;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;

      .cut-icon {
        width: 10px;
        height: 10px;
        margin-left: 1px;
      }
    }

    input {
      width: 180px;
      display: block;
      border-radius: 5px 0 0 5px;
      // height: 100%;
      flex: 1;
      font-size: 13px;
      padding-left: 10px;
      box-sizing: border-box;
    }

    .search-btn {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .user-right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .download {
    font-weight: 500;
  }

  .my-course {
    font-weight: 500;
    margin-left: 20px;
    padding: 0 20px;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
  }

  .login {
    padding-left: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;

    .line-clamp-1 {
      max-width: 200px;
    }

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-image: url("@/assets/img/avatar@2x.jpg");
      background-size: 100%;
      margin-left: 10px;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .connect-pop {
    text-align: center;

    .name {
      font-weight: 600;
      font-size: 18px;
    }

    img {
      width: 250px;
      margin: 25px 0;
    }

    .tips {
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>

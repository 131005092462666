import * as LoginApi from '@/api/login'
import storage from '@/utils/storage'
import router from '@/router'

export default {
  state: {
    token: storage.get('token') || ''
  },
  mutations: {
    SET_TOKEN: (state, value) => {
      storage.set('token', value)
      state.token = value
    }
  },
  actions: {
    Login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        LoginApi.login(data)
          .then(response => {
            if (response.status === 200) {
              commit('SET_TOKEN', response.data.token)
              resolve(response)
            } else {
              // reject(response)
            }
          })
          .catch(reject)
      })
    },

    loginByCode ({ commit }, data) {
      return new Promise((resolve, reject) => {
        LoginApi.loginByCode(data)
          .then(response => {
            if (response.status === 200) {
              commit('SET_TOKEN', response.data.token)
              resolve(response)
            } else {
              // reject(response)
            }
          })
          .catch(reject)
      })
    },

    switchUser ({ commit }, data) { // 切换用户
      commit('SET_TOKEN', data)
    },

    mobileLogin ({ commit }, data) {
      return new Promise((resolve, reject) => {
        LoginApi.mobileLogin({ form: data })
          .then(response => {
            if (response.status === 200) {
              commit('SET_TOKEN', response.data.token)
              resolve(response)
            } else {
              // reject(response)
            }
          })
          .catch(reject)
      })
    },

    LogOut ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        if (storage.get('is-code')) { // 快捷登录
          LoginApi.logout({ redirect_url: `${location.origin}/#/auth` }).then(result => {
            // location.href = result.message
            resolve()
          })
        } else {
          setTimeout(() => {
            router.push({ path: '/login', query: { callback: router.currentRoute.fullPath } })
            resolve()
          }, 500)
        }
      })
    }
  },
  getters: {
    getToken (state) {
      return state.token
    }
  }
}
